<template>
  <section class="section">
    <base-header>
      <template v-slot:header-table>
        <h1>{{ name }}</h1>
      </template>
      <template v-slot:item-slot>
        <div class="breadcrumb-item">Menu</div>
        <div class="breadcrumb-item">{{ name }}</div>
      </template>
    </base-header>

    <div class="section-body">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h4>{{ name }}</h4>
            </div>
            <form @submit.prevent>
              <div class="card-body">
                <div class="form-group">
                  <label>Name</label>
                  <input type="text" class="form-control" v-model="form.name" />
                  <small class="text-danger" v-if="errors.name">{{
                    errors.name[0]
                  }}</small>
                </div>
                <div class="form-group">
                  <label>Path</label>
                  <input type="text" v-model="form.path" class="form-control" />
                </div>
                <small class="text-danger" v-if="errors.path">{{
                  errors.path[0]
                }}</small>
                <div class="form-group">
                  <label>Icon</label>
                  <input type="text" v-model="form.icon" class="form-control" />
                  <a
                    href="https://fontawesome.com/v5.15/icons?d=gallery&p=2&m=free"
                    target="_blank"
                    >Font Awesome</a
                  >
                </div>
                <small class="text-danger" v-if="errors.icon">{{
                  errors.icon[0]
                }}</small>
                <div class="form-group">
                  <label>Component</label>
                  <input
                    type="text"
                    v-model="form.component"
                    class="form-control"
                  />
                </div>
                <small class="text-danger" v-if="errors.component">{{
                  errors.component[0]
                }}</small>
                <div class="form-group">
                  <label>Show</label>
                  <select class="form-control" v-model="form.is_index">
                    <option :value="true">True</option>
                    <option :value="false">False</option>
                  </select>
                </div>
                <small class="text-danger" v-if="errors.is_index">{{
                  errors.is_index[0]
                }}</small>
              </div>
              <div class="card-footer text-right">
                <button
                  class="btn btn-primary mr-1"
                  type="submit"
                  @click="menuUpdate"
                >
                  Submit
                </button>
                <button class="btn btn-secondary" type="reset">Reset</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import axios from "axios";
export default {
  name: "MenuChildEdit",
  data() {
    return {
      name: "Edit Submenu",
      form: {
        id: this.$route.params.id,
        name: "",
        path: "",
        component: "",
        icon: "",
        is_index: "",
      },
      errors: {},
    };
  },
  beforeMount() {
    axios
      .get(this.$store.state.api + `menu_child/${this.form.id}`, {
        headers: {
          Authorization: this.$store.state.token,
        },
      })
      .then((res) => {
        let data = res.data.data;

        this.form = {
          id: data.id,
          name: data.name,
          path: data.path,
          component: data.component,
          icon: data.icon,
          is_index: Boolean(Number(data.is_index)),
        };
      })
      .catch((err) => console.log(err));
  },
  methods: {
    menuUpdate() {
      let formData = new FormData();
      formData.set("name", this.form.name);
      formData.set("path", this.form.path);
      formData.set("component", this.form.component);
      formData.set("icon", this.form.icon);
      if (this.form.is_index == true) {
        formData.set("is_index", 1);
      } else {
        formData.set("is_index", 0);
      }
      axios
        .post(
          this.$store.state.api + `menu_child/update/${this.form.id}`,
          formData,
          {
            headers: {
              Authorization: this.$store.state.token,
            },
          }
        )
        .then(() => {
          this.$noty.success("Your submenu has been updated!");
          this.$router.back();
        })
        .catch((error) => {
          this.errors = error.response.data;
        });
    },
  },
};
</script>
